/* eslint-disable prefer-destructuring */
import { useState } from 'react';
import _ from 'lodash';
import {
	Accordion,
	AccordionItem,
	AccordionItemButton,
	AccordionItemHeading,
	AccordionItemPanel,
	AccordionItemState
} from 'react-accessible-accordion';
import { useTranslation } from 'react-i18next';
import { Page } from '../../components/Page';
import { getCustomerTypes, getTicketTypes } from '../Shop/Shop.data';
import { Loader } from '../../components/Loader';
import { TagList, TypeTagItem } from '../../components/modular/TagList';
import { Card } from '../../components/Card';
import { TicketType } from '../../types/Models';
import { ReactComponent as PlusSVG } from '../../assets/vectors/plus.svg';
import { ReactComponent as MinusSVG } from '../../assets/vectors/minus.svg';

type TicketTypeCategory = { category: string; description: string };
type TicketTypeCategoryWithTickets = {
	category: string;
	description: string;
	tickets: TicketType[];
};

// generate a list of categories with description
export const getCategories = ( serverList: any ): TicketTypeCategory[] => {
	const categoryList: TicketTypeCategory[] = [];

	serverList.forEach( ( { category } ) => {
		const newEntry: TicketTypeCategory = {
			category: category.name,
			description: category.description
		};

		! _.find( categoryList, newEntry ) && categoryList.push( newEntry );
	} );

	return categoryList;
};

// generate a unique list of tags from categories for the TagList component
export const getTags = ( categories: any ): TypeTagItem[] =>
	[ ...categories ].map( ( { category } ) => ( {
		label: category,
		active: false
	} ) );

// prepare a list for render
// generates a list of categories with description and tickets
export const formatList = (
	serverList: any,
	tags: TypeTagItem[],
	categories: TicketTypeCategory[]
): TicketTypeCategoryWithTickets[] => {

	// filter tag check only if not all the labels are inactive
	const canCheckTagFilter = tags.find( ( { active } ) => active );
	let results: TicketTypeCategoryWithTickets[] = [];

	// map tickets to their category
	categories?.forEach( ( { category, description } ) => {
		const tickets: TicketType[] = [];

		serverList.forEach(
			( { category: item_category, name, supported_types, show_in_shop } ) => {

				if ( category !== item_category.name || ! show_in_shop )
					return;

				// Sort customer_type
				supported_types.sort( ( a, b ) => ( a.id > b.id ? 1 : -1 ) );

				tickets.push( {
					name,
					show_in_shop,
					supported_types
				} );
			}
		);


		results.push( { category, description, tickets } );
	} );

	// Clean list from categories with no tickets in it.
	results = results.filter( ( { tickets } ) => tickets.length > 0 );

	// only add active categories
	if ( canCheckTagFilter ) {
		const filtered: TicketTypeCategoryWithTickets[] = [];

		tags?.forEach( ( { label, active } ) => {

			for ( let index = 0; index < results.length; ++index )
				if ( label === results[ index ].category && active )
					filtered.push( results[ index ] );
		} );

		return filtered;
	}

	return results;
};

// component
export default (): JSX.Element => {
	const [ tags, setTags ] = useState<TypeTagItem[] | any>();
	const [ t ] = useTranslation( 'common' );
	const [ categories, setCategories ] = useState<TicketTypeCategory[] | any>();
	const { data, isLoading } = getTicketTypes( true );
	const { data: customerTypeData, isLoading: isLoadingCustomerTypes } = getCustomerTypes( true );

	if ( isLoading || isLoadingCustomerTypes )
		return <Loader />;

	const ticketTypes = data.results;
	const customerTypes = customerTypeData.results;
	const getCustomerType = ( id: number ) =>
		customerTypes.find( ( customerType ) => customerType.id === id );

	// first make sure categories are sorted
	if ( ! categories ) {
		setCategories( getCategories( ticketTypes ) );
		return <Loader />;
	}

	// make sure tags are available
	if ( ! tags ) {
		setTags( getTags( categories ) );
		return <Loader />;
	}

	// Ugly & overengineered workaround to display only categories and tags which contains tickets in the current season.
	// FIXME: Simplify the whole page!
	const priceList = formatList( ticketTypes, tags, categories );

	const updatedCategories = categories.filter(
		( { category } ) => priceList.find( el => el.category === category )
	);

	const updatedTags = tags.filter(
		( { label } ) => updatedCategories.find( el => el.category === label )
	);

	return (
		<Page title={ t( 'basics.pricelist' ) }>
			<TagList type="multi" onChange={ setTags } tags={ updatedTags } />

			<Card>
				<Accordion allowZeroExpanded onChange={ () => true }>
					{ priceList.map(
						( { category, tickets } ) => (
							<AccordionItem>
								<AccordionItemHeading>
									<AccordionItemButton>
										{ category }
										<AccordionItemState>
											{ ( { expanded } ) =>
												expanded ? (
													<div className="accordion-item">
														<MinusSVG />
													</div>
												) : (
													<div className="accordion-item">
														<PlusSVG />
													</div>
												)
											}
										</AccordionItemState>
									</AccordionItemButton>
								</AccordionItemHeading>

								{ tickets.map(
									( { name, show_in_shop, supported_types } ) =>
										// Render nested Accordion only, if statement is true.
										show_in_shop &&
										supported_types &&
										supported_types.length && (
											<AccordionItemPanel>
												{/* only display customertype if customer_types exists in supported_type */}
												{ supported_types.length > 1 ? (
													<Accordion allowZeroExpanded onChange={ () => true }>
														<AccordionItem>
															<AccordionItemHeading>
																<AccordionItemButton>
																	<div className="nested-accordion-btn">
																		{ name }
																	</div>

																	<AccordionItemState>
																		{ ( { expanded } ) =>
																			expanded ? (
																				<div className="accordion-item">
																					<MinusSVG />
																				</div>
																			) : (
																				<div className="accordion-item">
																					<PlusSVG />
																				</div>
																			)
																		}
																	</AccordionItemState>
																</AccordionItemButton>
															</AccordionItemHeading>

															<AccordionItemPanel>
																<div className="priceListItem">
																	<ul>
																		{ supported_types.map(
																			( {
																				base_price,
																				show_in_list,
																				customer_types
																			} ) =>
																				show_in_list && (
																					<li>
																						<p>
																							{ customer_types?.map(
																								( { customer_type } ) =>
																									getCustomerType( customer_type )
																										.name
																							) }
																						</p>

																						<div className="price">
																							{ Number( base_price )
																								.toFixed( 2 )
																								.replace( '.', ',' ) }{ ' ' }
																							€
																						</div>
																					</li>
																				)
																			)
																		}
																	</ul>
																</div>
															</AccordionItemPanel>
														</AccordionItem>
													</Accordion>
												) : (
													// if ticket has no supported_type then shoe only tickettype withit price
													<div className="no-support_types">
														<p>{ name }</p>

														<div className="price">
															{ supported_types.map( ( { base_price } ) =>
																Number( base_price ).toFixed( 2 ).replace( '.', ',' )
															) }
															€
														</div>
													</div>
												) }
											</AccordionItemPanel>
										)
								) }
							</AccordionItem>
						)
					) }
				</Accordion>
			</Card>
		</Page>
	);
};
