import { FC, ReactNode, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { Header, HeaderProps } from '../modular/Header';
import {
	BottomNavigation,
	BottomNavigationProps
} from '../modular/BottomNavigation';
import { ReactComponent as HomeIcon } from '../../assets/vectors/home.svg';
import { ReactComponent as TicketIcon } from '../../assets/vectors/ticket.svg';
import { ReactComponent as CartIcon } from '../../assets/vectors/shopping-cart.svg';
import { ReactComponent as StackIcon } from '../../assets/vectors/menu.svg';
import { Loader } from '../Loader';
import { useRouter } from '../../routes/Router.hooks';
import {
	areWeTestingWithJest,
	isLoggedIn,
	isOnline
} from '../../utils/helpers';
import { ShopPresistor } from '../../features/Shop/Shop.data';
import { getPushNotes } from '../../features/Settings/Settings.data';

const MenuItemsInitial: BottomNavigationProps['items'] = [
	{
		label: 'start',
		address: '/',
		active: window.location.pathname === '/',
		Icon: areWeTestingWithJest() ? () => <HomeIcon /> : <HomeIcon />
	},
	{
		label: 'meine tickets',
		address: '/tickets',
		active: window.location.href.includes( '/tickets' ),
		Icon: areWeTestingWithJest() ? () => <TicketIcon /> : <TicketIcon />
	},
	{
		label: 'warenkorb',
		address: '/basket',
		badge: false,
		active: window.location.href.includes( '/basket' ),
		Icon: areWeTestingWithJest() ? () => <CartIcon /> : <CartIcon />
	},
	{
		label: 'menu',
		address: '/settings',
		badge: false,
		active: window.location.href.includes( '/settings' ),
		Icon: areWeTestingWithJest() ? () => <StackIcon /> : <StackIcon />
	}
];
export const Page: FC<PageProps> = ( {
	title,
	children,
	headerActive = true,
	rightItems,
	noBackButton,
	isLoadingWithMessage = ''
}: PageProps ) => {
	const route = useRouter();
	const activeOrder = ShopPresistor().getActiveOrder();
	let unread: number | any = null;

	if ( isLoggedIn() ) {
		const activeNotes = getPushNotes().get().results;
		unread = activeNotes.filter( ( { is_read } ) => !is_read ).length;
	}

	const location: any = useLocation();
	const classNames: string[] = ['Page'];
	const className: string = classNames.join( ' ' );
	const [ menuItems, setMenuItems ] = useState( MenuItemsInitial );
	const [ t ] = useTranslation( 'common' );

	useEffect( () => {
		setMenuItems( [
			{
				label: t( 'navbar.home' ),
				address: '/',
				active: window.location.pathname === '/',
				Icon: <HomeIcon />
			},
			{
				label: t( 'navbar.tickets' ),
				address: '/tickets',
				active: window.location.href.includes( '/tickets' ),
				Icon: <TicketIcon />
			},
			{
				label: t( 'navbar.basket' ),
				address: '/basket',
				badge: isLoggedIn() && activeOrder.tickets?.length,
				active: window.location.href.includes( '/basket' ),
				disabled: !isOnline(),
				Icon: <CartIcon />
			},
			{
				label: t( 'navbar.settings' ),
				address: '/settings',
				badge: isLoggedIn() && unread !== 0 ? unread : false,
				active: window.location.href.includes( '/settings' ),
				Icon: <StackIcon />
			}
		] );
	}, [ title, location ] );

	return (
		<div className={ className as string }>
			<Helmet>
				<meta charSet="utf-8" />

				<meta name="viewport" content="width=device-width, user-scalable=no" />

				<title>{ title } | { process.env.REACT_APP_NAME } Shop</title>
			</Helmet>

			<div className="Page__wrapper">
				{ headerActive && (
					<header>
						{ ! noBackButton && (
							<Header
								renderControl={
									! ShopPresistor().getFlags().incert_package.status
								}
								rightItems={ rightItems }
								title={ title }
								onBack={ () =>
									location.state && location.state?.id === 'TT2N-APP'
										? route.goBack()
										: route.push( '/' )
								}
							/>
						) }
						{ noBackButton && <Header rightItems={ rightItems } title={ title } /> }
					</header>
				) }

				{ isLoadingWithMessage && <Loader message={ isLoadingWithMessage } /> }

				<section>{ children }</section>

				
			</div>

			<nav>
				<BottomNavigation
					items={ menuItems }
					onClick={ ( routeLabel: string ) => route.push( routeLabel ) }
				/>
			</nav>
		</div>
	);
};

export interface PageProps {
	title: string;
	children: ReactNode;
	headerActive?: boolean;
	noBackButton?: boolean;
	isLoadingWithMessage?: any;
	rightItems?: HeaderProps['rightItems'];
}
