/* eslint-disable prefer-destructuring */
/* eslint-disable prefer-template */

import { ReactNode } from 'react';
import { useTranslation } from 'react-i18next';
import { Page } from '../../components/Page';
import logo from '../../assets/logo.png';
import { Button } from '../../components/Button';
import { useRouter } from '../../routes/Router.hooks';
import { getSettings } from '../Settings/Settings.data';
import { Loader } from '../../components/Loader';
import { MenuPoint, Settings } from '../../types/Models';
import { Alert } from '../../components/Alert';
import { isLoggedIn, isOnline, setTokenFromWindow } from '../../utils/helpers';
import BergePNG from '../../assets/Berge.png';
import DiamantPNG from '../../assets/Diamant.png';
import GoldPNG from '../../assets/Gold.png';
import SilberPNG from '../../assets/Silber.png';
import Fundings from '../../assets/Fundings.png';

const renderLogo = ( user: any ) => {

	if ( ! user ) {
		document.body.style.backgroundColor = '#ecf0f1';
		return <img src={logo} alt="logo" />;
	}

	const { partner_level, first_name, last_name } = user;

	if ( ! partner_level ) {
		document.body.style.backgroundColor = '#ecf0f1';
		return <img src={logo} alt="logo" />;
	}

	let image: any = null;

	switch ( partner_level ) {

		case 'silber':
			document.body.style.backgroundColor = '#E0EAF4';
			image = <img src={SilberPNG} alt="silber-badge" />;
			break;

		case 'gold':
			document.body.style.backgroundColor = '#F5F0DC';
			image = <img src={GoldPNG} alt="gold-badge" />;
			break;

		case 'diamant':
			document.body.style.backgroundColor = '#F3F5FF';
			image = <img src={DiamantPNG} alt="diamant-badge" />;
			break;
	}

	return (
		<div className={ `BadgeBox BadgeBox-${partner_level}` }>
			{ image }
			<div>
				<span>{ partner_level } Partner</span>
				<h3>{ first_name } { last_name }</h3>
			</div>

			<img src={ BergePNG } alt="mountain-badge" className="BadgeBox-bg" />
		</div>
	);
};

const renderIntro = ( settings?: Settings ): ReactNode => {
	if ( settings && settings.intro_text )
		return <div dangerouslySetInnerHTML={ { __html: settings.intro_text } } />;

	return <></>;
};

const renderMenu = ( settings?: Settings ): ReactNode | boolean => {
	if ( ! settings || ! settings.menu_points )
		return true;

	const items = settings.menu_points.map(
		( menuPoints: MenuPoint, index ) => {
			const { is_disabled, uri, label } = menuPoints;

			return (
				! is_disabled && (
					<Button
						key={ index }
						label={ label }
						type="secondary"
						onClick={ () => window.open( uri, '_blank' ) }
						disabled={ ! isOnline() }
					/>
				)
			);
		}
	);

	return items;
};

export default (): JSX.Element => {
	// get settings
	const route = useRouter();
	let settings: any = null;
	let isOffSeason: any = null;
	let user = null;

	const { data, isLoading } = getSettings( false );
	const [ t ] = useTranslation( 'common' );
	const loggedData = isLoggedIn( true );

	if ( loggedData ) {
		const loggedIn = loggedData[0];
		const userData = loggedData[1];

		if ( loggedIn ) {
			if ( userData.isLoading )
				return <Loader />;

			user = userData.data.results[0];
		}
	}

	if ( isLoading )
		return <Loader />;

	settings = data.results[0];
	isOffSeason = settings.active_season.is_off_season;
	setTokenFromWindow();

	return (
		<Page title="Start" headerActive={false}>
			{ renderLogo( user ) }

			{ renderIntro( settings ) }

			{ isOffSeason && settings && (
				<Alert show type="warning">
					Der Shop ist bis zum
					{ settings.active_season.end_date }
					in Betriebsferien!
				</Alert>
			) }

			{ ! isOnline() && (
				<>
					<Alert show type="warning">
						Keine Verbindung!
					</Alert>
					<br />
				</>
			) }

			<Button
				label={ t( 'start.buy-btn' ) }
				onClick={ () => route.push( '/shop' ) }
				data-cy="btn__BuyTicket-HomePage"
				disabled={ isOffSeason || ! isOnline() }
			/>

			{ renderMenu( settings ) }

			<Button
				label={ t( 'start.price-btn' ) }
				type="primary"
				onClick={ () => route.push( '/shop/list' ) }
				data-cy="btn__PriceList-HomePage"
			/>

			<img className="fundings" src={ Fundings } alt="gefördert durch" />
		</Page>
	);
};
