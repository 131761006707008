import { RouteObject } from '../../types/Types';
import Login from './Login.page';
import PostLogin from './PostLogin.page';
import Logout from './Logout.page';
import Register from './Registration.page';
import Guest from "./Guest.page";
import RequestPasswordReset from './RequestPasswordReset.page';
import SetNewPassword from './SetNewPassword.page';

export default [
	{
		name: 'login_page',
		link: '/login',
		component: <Login />,
		isProtected: false
	},
	{
		name: 'post_login_page',
		link: '/authenticate',
		component: <PostLogin />,
		isProtected: true
	},
	{
		name: 'logout_page',
		link: '/logout',
		availableOffline: true,
		component: <Logout />,
		isProtected: true
	},
	{
		name: 'register_page',
		link: '/register',
		component: <Register />,
		isProtected: false
	},
	{
		name: "guest_page",
		link: "/guest",
		component: <Guest />,
		isProtected: false,
	},
	{
		name: 'request_password_reset_page',
		link: '/request-password-reset',
		component: <RequestPasswordReset />,
		isProtected: false
	},
	{
		name: 'reset_new_password_page',
		link: '/reset-new-password',
		component: <SetNewPassword />,
		isProtected: false
	}
] as unknown as RouteObject[];
