/* eslint consistent-return: "off" */
import { SWRConfig } from 'swr';
import axios from 'axios';
import i18n from 'i18next';
import moment from 'moment';
import { encode } from 'base64-arraybuffer';
import { downloadQrCodes } from '../features/Shop/Shop.data';
import { isOnline, postMessage, isLoggedIn } from './helpers';

// constants
const DATA_PROVIDER_REFRESH_INTERVAL = 60000;
const AXIOS_TIMEOUT = 30000;
export const BASE_URL = `${process.env.REACT_APP_INSTANCE_URL}/api`;

const translationOptions = { ns: 'common' };

// Error code mappings
const getMessageForErrorCode = async (
	endpoint: string,
	code: string,
	errorObject: any
) => {

	// Tickets
	if ( /^(order)\/([0-9]+)\/(ticket)\/$/.test( endpoint ) )
		return errorObject;

	// Refund
	if ( /^(\/order)\/([0-9]+)\/(payment\/)$/.test( endpoint ) )
		return i18n.t( `errors.payment.${code}`, translationOptions );

	// Activation
	if ( /^(ticket)\/([0-9]+)\/(activate)\/$/.test( endpoint ) ) {
		const globalClosing =
			getLocalResource( '/settings/' ).results[0].closing_time;
		const id = endpoint.match( /[0-9]+/ );

		if ( ! id )
			return i18n.t( 'errors.default_msg', translationOptions );

		const type = await HeyServer.get( `/ticket/?id=${id}` )
			.then( ( res: any ) => res.results[0].typ.ticket_type )
			.catch( ( e ) => console.error( e as string ) );

		const latestHour = type.latest_hour
			? type.latest_hour - type.activation_duration
			: globalClosing - type.activation_duration;

		switch ( code ) {
			case 'limit':
			case 'too-soon':
			case 'season-late':
				return i18n.t( `errros.activation.${code}`, translationOptions );

			case 'too-early':
				return i18n.t( 'errors.activation.too-early', {
					...translationOptions,
					startH: type.start_hour
				} );

			case 'too-late':
				return i18n.t( 'errors.activation.too-late', {
					...translationOptions,
					latestH: latestHour
				} );

			case 'season-early':
				return i18n.t( 'errors.activation.season-early', {
					...translationOptions,
					startD: type.season.start_date
				} );
		}
	}

	// Orders
	if ( /^(order)\/([0-9]+)\/(code)\/$/.test( endpoint ) )
		return i18n.t( `errors.order.${code}`, translationOptions );

	switch ( endpoint ) {
		case '/pw-reset-request':
		case '/register':
		case '/login':
			return i18n.t( `errors.user.${code}`, translationOptions );

		case '/order/code_type':
			return i18n.t( `errors.code_type.${code}`, translationOptions );

		default:
			return i18n.t( 'errors.default_msg', translationOptions );
	}
};
// Axios config

export const HeyServer = axios.create( {
	baseURL: BASE_URL,
	timeout: AXIOS_TIMEOUT,
	headers: {
		'Content-Type': 'application/json'
	}
} );

// customise reponse
HeyServer.interceptors.response.use(
	( { data: resData } ): any => resData,
	async ( error ) => {

		if ( ! error || ! error.response )
			return;

		const msg = error.toJSON().message;

		// logout if the server returns 402 or 403
		if (
			error.response.config.url !== '/login' &&
			( msg.includes( '401' ) || msg.includes( '403' ) )
		) {
			window.localStorage.clear();
			window.location.href = '/';
		}

		const errorMsg = await getMessageForErrorCode(
			error.response.config.url,
			error.response.data.code,
			error
		);

		return Promise.reject( errorMsg );
	}
);

HeyServer.interceptors.request.use(
	( request ) => {
		const tokenDataRaw: any = window.localStorage.getItem( 'user_token' );
		const tokenData = JSON.parse( tokenDataRaw );
		// Send it to native for sync
		if ( tokenData && tokenDataRaw && tokenData.token ) {
			postMessage( 'ADD_SESSION', JSON.parse( tokenDataRaw ) );
			request.headers.Authorization = `Token ${tokenData.token}`;
		}
		return request;
	},
	( error ) => true
);

// SWR config
const storageInterceptor = async ( url: string, payload: any ) => {

	// Strip search params from ticket links.
	const data = await payload;
	const targetUrl = url;

	if ( targetUrl.includes( '/ticket/' ) ) {
		return downloadQrCodes( payload.results )
			.then( ( ...res: any ) => {
				let tickets = [...payload.results];
				const { next } = payload;
				const { previous } = payload;
				const images = res[0][0];

				tickets = tickets.map( ( ticket, index ) => ( {
					...ticket,
					imageUrl: encode( images[index] )
				} ) );

				window.localStorage.setItem(
					targetUrl,
					JSON.stringify( { results: tickets, timestamp: moment() } )
				);

				return { results: tickets, next, previous };
			} )

			.catch( ( e ) => {
				console.log( e );
				return { results: [] };
			} );
	}

	data.timestamp = moment();
	window.localStorage.setItem( targetUrl, JSON.stringify( data ) );

	return payload;
};

export const DataProvider = ( { children }: any ) => {
	const token = window.localStorage.getItem( 'user_token' );

	if ( ! isLoggedIn() && ! token ) {
		HeyServer.get( '/login_anon' )
			.then( ( res ) => {
				window.localStorage.setItem(
					'user_token',
					JSON.stringify( { ...res, anon: true } )
				);
			} )

			.catch( ( e ) => console.log( e ) );
	}

	const handleFetch = ( url: string, forceAPICall = false ) => {
		// First try to get the local item.
		const localItem = getLocalResource( url );

		// Return local item, if not forced to call the API or timestamp is less then treshold.
		if (
			localItem &&
			!forceAPICall &&
			moment().diff( localItem.timestamp, 'minutes' ) < 5
		) {
			return localItem;
		}

		// Forced or to old timestamp.
		if ( isOnline() ) {
			return HeyServer.get( url )
				.then( ( upcoming ) => storageInterceptor( url, upcoming ) )
				.catch( ( e ) => console.log( e ) );
		}

		// Forced or to old timestamp, but user is offline.
		return localItem;
	};
	const config = {
		refreshInterval: DATA_PROVIDER_REFRESH_INTERVAL,
		fetcher: handleFetch
	};

	return <SWRConfig value={config}>{children}</SWRConfig>;
};

export const getLocalResource = ( urlKey: string, withResults = false ): any => {
	// Strip search params from ticket links.
	let targetKey = urlKey;
	if ( targetKey.includes( 'ticket' ) )
		targetKey = targetKey.replace( /\?.+/, '' );

	if ( ! window.localStorage.getItem( targetKey ) )
		return null;

	const resource: any = JSON.parse(
		window.localStorage.getItem( targetKey ) as any
	) as string;

	if ( resource ) {
		if ( withResults )
			return resource.results;

		return resource;
	}

	return null;
};
