// eslint-disable jsx-props-no-spreading
/* eslint-disable prefer-destructuring */
/* eslint-disable no-nested-ternary */

import { useState, Fragment } from 'react';
import { useTranslation } from 'react-i18next';
import moment from 'moment';
import { mutate } from 'swr';
import { confirmAlert } from 'react-confirm-alert'; // Import
import 'react-datetime/css/react-datetime.css';
import { Page } from '../../components/Page';
import { useRouter } from '../../routes/Router.hooks';
import { getCustomerTypes, getTickets } from './Shop.data';
import { ReactComponent as ShareSVG } from '../../assets/vectors/share.svg';
import { Pagination } from '../../components/modular/Pagination';
import { Loader } from '../../components/Loader';
import { Card } from '../../components/Card';
import { Alert } from '../../components/Alert';
import { Button } from '../../components/Button';
import { Order } from '../../types/Models';
import { Divider } from '../../components/Divider';
import { HeyServer } from '../../utils/server';
import {
	downloadOffline,
	isOnline,
	shareImage
} from '../../utils/helpers';

export default (): any => {
	const [ page, setPage ] = useState( 1 );
	const route = useRouter();
	const [ t ] = useTranslation( 'common' );
	// redirect back to first step of params are corrupt
	const [ serverError, setServerError ] = useState( '' );
	// when new price is being retrieved from the server
	const [ loading, setLoading ] = useState( false );
	// SWR calls
	const { data, isLoading } = getTickets( true, page );
	const { data: customerTypeData, isLoading: customerIsLoading } =
		getCustomerTypes( false );

	// dont proceed until loaded
	if ( isLoading || customerIsLoading )
		return <Loader />;

	if ( ! data.results.length ) {
		return (
			<Page title={ t( 'tickets.title' ) }>
				<div className="EmptyCartContainer">
					<Card>
						<p>{ t( 'tickets.empty_info' ) }</p>

						<h1>{ t( 'cart_cta_txt', { ns: 'custom' } ) }</h1>

						<Button
							onClick={ () => route.push( '/shop' ) }
							label={ t( 'buttons.select_ticket' ) }
						/>
					</Card>
				</div>
			</Page>
		);
	}

	const getTicketName = ( customerTypes: any ) => {

		if ( customerTypes.length < 2 ) {
			const target = customerTypes[0].customer_type;
			const currentType = customerTypeData.results.find( ( { id } ) => id === target );

			if ( ! currentType )
				return 'student';

			return currentType.name;
		}

		const typeNames = customerTypes.map( ( { count, customer_type } ) => {
			const name = customerTypeData.results.find( ( { id } ) => id === customer_type ).name;

			return `${count} ${name}`;
		} );

		return typeNames.join( ', ' );
	};

	const downloadTicket = ( ticketId: number, contactName: string ) => {
		HeyServer.get( `ticket/${ticketId}/qr_share/`, {
			responseType: 'arraybuffer'
		} )

		.then( ( res: any ) => {
			shareImage( new Blob( [res] ), contactName );
		} )

		.catch( ( e ) => {
			setServerError( e );
		} );
	};

	const activeTickets = data.results.filter( ( el: any ) =>
		moment().isBetween( el.valid_from, el.valid_until )
	);

	const inactiveTickets = data.results.filter(
		( el: any ) => moment().isBefore( el.valid_from )
	);

	const disabledTickets = data.results.filter(
		( el: any ) =>
			moment().isAfter( el.valid_until ) ||
			( ! el.typ.ticket_type.is_flexible &&
				moment().isAfter( el.for_time ) &&
				! el.activations.length )
	);

	const tickets = activeTickets.concat( inactiveTickets ).concat( disabledTickets );

	return (
		<Page title={ t( 'tickets.title' ) }>
			<Loader show={ loading } />
			<div className="MyTicketsContainer">
				{ serverError && (
					<>
						<br />
						<Alert type="error" show>
							<p>{ serverError }</p>
						</Alert>
					</>
				) }

				<Card>
					<div className="CartBody">
						<div className="MyTicketsContainer__header">
							<p>{ t( 'tickets.card_header.ticket' ) }</p>
							<p>{ t( 'tickets.card_header.valid' ) }</p>
							<p>Download</p>
						</div>

						<Divider />

						{ tickets.map(
							( {
								typ,
								contacts,
								imageUrl,
								id: ticketId,
								valid_from,
								valid_until
							}: any | Order ) => {

								const isSaison = typ.ticket_type.activation_type === 2;
								const isActive = moment().isBetween( valid_from, valid_until );
								const isExpired = ! valid_until || moment().isAfter( valid_until );

								return contacts.map( ( { contact }: any | Order ) => (
									<Fragment key={ contact.id }>
										<div
											className={ `CartItem CartItem__${
												isExpired
													? 'expired'
													: isActive
													? 'active'
													: 'inactive'
											}` }
										>
											<div
												className={ `CartItem__left${
													isExpired || ! isActive
														? ' inactive'
														: ''
												}` }
												onClick={ () =>
													! isExpired && route.push( `/tickets/${ticketId}` )
												}
											>
												<h1>{ typ.ticket_type.name }</h1>

												<p>{ getTicketName( typ.customer_types ) }</p>

												<span>{ contact.name }</span>
											</div>

											<div className="CartItem__center">
												<div
													className="active-info"
													onClick={ () =>
														! isExpired && route.push( `/tickets/${ticketId}` )
													}
												>
													<h1>
														{
															isExpired
																? t( 'basics.expired' )
																: isActive
																? t( 'basics.active' )
																: ''
														}
													</h1>

													<div>
														{ ! isExpired && isActive && (
															( ! isSaison ? (
																<>
																	<p>
																		{ `${t( 'basics.on' )} ${moment
																		.parseZone( valid_until )
																		.local( true )
																		.format( 'DD.MM.YYYY' )}` }
																	</p>

																	<p>
																		{ `${t( 'basics.until' )} ${moment
																			.parseZone( valid_until )
																			.local( true )
																			.format( 'HH:mm [Uhr]' )}` }
																	</p>
																</>
															) : (
																<p>
																	{ `${t( 'basics.until' )} ${moment
																		.parseZone( valid_until )
																		.local( true )
																		.format( 'DD.MM.YYYY' )}` }
																</p>
															)
															)
														) }
													</div>
												</div>

												{ ! isExpired && (
													<div className="active">
														<Button
															onClick={ () => {
																if ( isOnline() ) {
																	return downloadTicket(
																		ticketId,
																		contact.name
																	);
																}

																return downloadOffline(
																	contact.name,
																	`data:image/png;base64,${imageUrl}`
																);
															} }
															Icon={ <ShareSVG /> }
														/>
													</div>
												) }
											</div>
										</div>
										<Divider />
									</Fragment>
								) );
							}
						) }
					</div>

					<div className="CartFooter">
						<Pagination
							maxPages={ null }
							currentPage={ page }
							nextPage={ () => data.next && setPage( page + 1 ) }
							previousPage={ () => data.previous && setPage( page - 1 ) }
						/>

						<Button
							type="secondary"
							label={ t( 'settings.orders.title' ) }
							onClick={ () => route.push( '/settings/orders' ) }
						/>
					</div>
				</Card>
			</div>
		</Page>
	);
};
