// eslint-disable jsx-props-no-spreading
/* eslint-disable prefer-destructuring */
import _ from 'lodash';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { Page } from '../../components/Page';
import { useRouter } from '../../routes/Router.hooks';
import { getCustomerTypes, getTickets } from './Shop.data';
import { ReactComponent as ShareSVG } from '../../assets/vectors/share.svg';
import { ReactComponent as SidebarSVG } from '../../assets/vectors/sidebar.svg';
import { Loader } from '../../components/Loader';
import { Card } from '../../components/Card';
import { Button } from '../../components/Button';
import { HeyServer } from '../../utils/server';
import {
	downloadOffline,
	isOnline,
	shareImage
} from '../../utils/helpers';

export default (): any => {
	const route = useRouter();
	const [t] = useTranslation( 'common' );

	// SWR calls
	const { data, isLoading } = getTickets( true );
	const { data: customerTypeData, isLoading: customerIsLoading } = getCustomerTypes( false );

	// dont proceed until loaded
	if ( isLoading || customerIsLoading )
		return <Loader />;

	const ticketId = route.pathname.replace( '/tickets/', '' );
	const ticket = data.results.find( ( { id } ) => Number( id ) === Number( ticketId ) );

	if ( ! ticket ) {
		route.push( '/tickets' );
		return <Loader />;
	}

	const { name } = ticket.contacts[0].contact;
	const { activation_type } = ticket.typ.ticket_type;

	const isSaison = activation_type === 2;
	const customerType = customerTypeData.results.find(
		( { id: cid } ) => cid === ticket.typ.customer_types[0].customer_type
	);


	const downloadTicket = ( id: number, contactName: string ) => {
		HeyServer.get( `ticket/${id}/qr_share/`, {
			responseType: 'arraybuffer'
		} )

		.then( ( res: any ): any => {
			const img = new Blob( [res] );
			return shareImage( img, contactName );
		} );
	};

	const getClassName = () => {
		if ( ! ticket.valid_until || moment( ticket.valid_until ).isBefore( moment() ) )
			return 'backdrop backdrop__expired';

		return 'backdrop backdrop__active';
	};

	const image = `data:image/png;base64,${ticket.imageUrl}`;

	return (
		<Page title={ t( 'my_ticket.title' ) }>
			<Card>
				<div className="MyTicketsDetailContainer">
					<div className={ getClassName() } />

					<div className="animation-gif" />

					{ customerType.rebate_card && (
						<div className="show-identity">
							<div>
								<span>{ t( 'my_ticket.show_id_card' ) }</span>
							</div>

							<div className="sidebar-icon">
								<SidebarSVG />
							</div>
						</div>
					) }

					<div className="text-info">
						<span>
							{ ticket.typ.ticket_type.name }

							{ ticket.valid_until && (
								<small>
									{ `${t( 'basics.until' )} ${moment
										.parseZone( ticket.valid_until )
										.local( true )
										.format( `DD.MM.YYYY ${! isSaison ? '- HH:mm [Uhr]' : ''}` )}` }
								</small>
							) }
						</span>

						<span>{ name }</span>

						<span>{ customerType.name }</span>
					</div>

					<img src={ image } alt="qr-code" className="qr-code-animation" />

					<Button
						label={ t( 'my_ticket.download' ) }
						type="secondary"
						onClick={ () => {
							if ( isOnline() ) {
								return downloadTicket(
									ticketId,
									ticket.contacts[0].contact.name
								);
							}

							return downloadOffline( ticket.contacts[0].contact.name, image );
						} }

						Icon={ <ShareSVG /> }
					/>
				</div>
			</Card>
		</Page>
	);
};
