/* eslint-disable no-return-assign */
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { mutate } from "swr";
import { Formik } from "formik";
import { Input } from "../../components/Input";
import { Page } from "../../components/Page";
import { Card } from "../../components/Card";
import { Button } from "../../components/Button";
import { Loader } from "../../components/Loader";
import { CheckBox } from "../../components/CheckBox";
import { useRouter } from "../../routes/Router.hooks";
import { Alert } from "../../components/Alert";
import { getUser, getSettings } from "../Settings/Settings.data";
import { isLoggedIn } from "../../utils/helpers";
import { HeyServer } from "../../utils/server";

export default (): any => {
	const route = useRouter();
	const [t] = useTranslation( "common" );
	// we dont want the user to see the login page ofc
	if ( isLoggedIn() ) {
		window.location.href = "/";
		route.push( "/" );
	}

	const [serverError, setServerError] = useState( "" );

	const { data, isLoading } = getUser( true );
	const { data: settingsData, isLoading: settingsLoading } = getSettings( false );
	if ( !data || isLoading || !settingsData || settingsLoading ) return <Loader />;

	const user = data.results[0];
	const { privacy_policy_date, terms_of_service_date } =
		settingsData.results[0];

	const onSubmit = ( values, { setSubmitting } ) => {
		const serverValues = {
			...values,
			pp_accepted: privacy_policy_date,
			tos_accepted: terms_of_service_date,
		};

		HeyServer.post( `/setup_guest`, serverValues )
			.then( () => {
				route.push( "/shop/payment" );
			} )
			.catch( ( e ) => setServerError( e ) )
			.finally( () => setSubmitting( false ) );
	};

	const validate = ( values ) => {
		const errors: any = {};
		if ( !values.email ) {
			errors.email = t( "erros.form.empty", {
				value: "E-Mail",
			} );
		}

		if (
			!/^((?!\.)[\w_.]*[^.])(@\w+)(\.\w+(\.\w+)?[^.\W])$/gim.test( values.email )
		) {
			errors.email = t( "errors.form.email" );
		}

		if ( !values.first_name ) {
			errors.first_name = t( "errors.form.empty", {
				value: t( "form_labels.name" ),
			} );
		}

		if ( !values.last_name ) {
			errors.last_name = t( "errors.form.empty", {
				value: t( "form_labels.surname" ),
			} );
		}

		if ( !values.pp_accepted ) {
			errors.pp_accepted = t( "form_labels.required" );
		}

		if ( !values.tos_accepted ) {
			errors.tos_accepted = t( "form_labels.required" );
		}

		return errors;
	};

	return (
		<Page title={t( "login.guest_title" )}>
			{serverError && (
				<Alert type="error" show>
					<p>{serverError}</p>
				</Alert>
			)}
			<Card>
				<Formik
					initialValues={{
						email: "",
						first_name: "",
						last_name: "",
						pp_accepted: false,
						tos_accepted: false,
					}}
					validate={validate}
					validateOnChange={false}
					validateOnBlur={false}
					onSubmit={onSubmit}
				>
					{( {
						values,
						errors,
						handleChange,
						handleSubmit,
						isSubmitting,
						setFieldValue,
					} ) => (
							<form onSubmit={handleSubmit}>
								<div className="row">
									<Input
										name="first_name"
										type="with-label"
										label={t( "form_labels.name" )}
										onChange={( value, e ) => handleChange( e )}
										value={values.first_name}
										onErrorMessage={errors.first_name as string}
									/>

									<Input
										name="last_name"
										type="with-label"
										label={t( "form_labels.surname" )}
										onChange={( value, e ) => handleChange( e )}
value={values.last_name}
										onErrorMessage={errors.last_name as string}
/>
								</div>
<Input
									inputType="email"
name="email"
									type="with-label"
									label="E-mail"
									cy="textinput__Guest-Email"
									onChange={( value, e ) => handleChange( e )}
									value={values.email}
									onErrorMessage={errors.email as string}
								/>
								<CheckBox
									label={t( "basics.policy" )}
									checked={values.pp_accepted}
									onChange={( v ) => setFieldValue( "pp_accepted", v )}
>
<>
{t( "form_labels.accept" )}{" "}
										<a href="/settings/policy">{t( "basics.policy" )}</a>
									</>
								</CheckBox>

								<CheckBox
									label={t( "basics.gtc" )}
									checked={values.tos_accepted}
									onChange={( v ) => setFieldValue( "tos_accepted", v )}
								>
									<>
{t( "form_labels.accept" )}{" "}
										<a href="/settings/terms">{t( "basics.gtc" )}</a>
									</>
								</CheckBox>

<Button
									actionType="submit"
									onClick={handleSubmit}
									cy="cta__Guest-submit"
label={t( "buttons.continue" )}
									disabled={
isSubmitting || !values.pp_accepted || !values.tos_accepted
									}
								/>
							</form>
						)}
</Formik>
			</Card>
		</Page>
	);
};
