import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import { RouteObject } from '../../types/Types';
import TicketType from './TicketType.page';
import TicketDate from './TicketDate.page';
import SelectPax from './SelectPax.page';
import PaxInfo from './PaxInfo.page';
import Cart from './Cart.page';
import Payment from './Payment.page';
import BillingInfo from './BillingInfo.page';
import Confirmation from './Confirmation.page';
import MyTickets from './MyTickets.page';
import MyTicketsDetail from './MyTicketsDetail.page';
import VoucherRedeem from './VoucherRedeem.page';

const stripePromise = loadStripe(
	process.env.REACT_APP_STRIPE_PUBLIC_KEY as string
);

export default [
	{
		name: 'my_tickets_page',
		link: '/tickets',
		availableOffline: true,
		component: <MyTickets />,
		isProtected: true
	},
	{
		name: 'my_ticket_page',
		link: '/tickets/:ticketId',
		availableOffline: true,
		component: <MyTicketsDetail />,
		isProtected: true
	},
	{
		name: 'ticket_type_page',
		link: '/shop',
		component: <TicketType />,
		isProtected: false
	},
	{
		name: 'voucher_redeem_page',
		link: '/voucher',
		component: <VoucherRedeem />,
		isProtected: false
	},
	{
		name: 'ticket_date_page',
		link: '/shop/select-date',
		component: <TicketDate />,
		isProtected: false
	},
	{
		name: 'pax_count_page',
		link: '/shop/select-pax',
		component: <SelectPax />,
		isProtected: false
	},
	{
		name: 'pax_info_page',
		link: '/shop/pax-info',
		component: <PaxInfo />,
		isProtected: false
	},
	{
		name: 'cart_page',
		link: '/basket',
		component: <Cart />,
		isProtected: false
	},
	{
		name: 'billing_page',
		link: '/shop/billing',
		component: <BillingInfo />,
		isProtected: true
	},
	{
		name: 'payment_page',
		link: '/shop/payment',
		component: (
			<Elements stripe={stripePromise}>
				<Payment />
			</Elements>
		),
		isProtected: false
	},
	{
		name: 'payment_success_page',
		link: '/shop/payment/confirmation',
		component: <Confirmation />,
		isProtected: false
	}
] as unknown as RouteObject[];
