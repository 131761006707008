/* eslint-disable prefer-destructuring */
import { useState } from 'react';
import { confirmAlert } from 'react-confirm-alert';
import { useTranslation } from 'react-i18next';
import { Page } from '../../components/Page';
import { useRouter } from '../../routes/Router.hooks';
import { getUser } from './Settings.data';
import { Loader } from '../../components/Loader';
import { VerticalMenu } from '../../components/modular/VerticalMenu';
import SettingsRoutes from './Settings.routes';
import { HeyServer } from '../../utils/server';
import { Card } from '../../components/Card';
import { Button } from '../../components/Button';
import { getPushNotes } from './Settings.data';

// icons
import { ReactComponent as BellSVG } from '../../assets/vectors/bell.svg';
import { ReactComponent as ShieldSVG } from '../../assets/vectors/shield.svg';
import { ReactComponent as GridSVG } from '../../assets/vectors/grid.svg';
import { ReactComponent as LayersSVG } from '../../assets/vectors/layers.svg';
import { ReactComponent as HelpSVG } from '../../assets/vectors/help-circle.svg';
import { ReactComponent as LoginSVG } from '../../assets/vectors/log-in.svg';
import { ReactComponent as HomeSVG } from '../../assets/vectors/home.svg';
import { ReactComponent as PaperSVG } from '../../assets/vectors/file-text.svg';
import { ReactComponent as LockSVG } from '../../assets/vectors/lock.svg';
import { isLoggedIn, isOnline } from '../../utils/helpers';

export default (): JSX.Element => {
	let unread = 0;
	const route = useRouter();
	const [ t ] = useTranslation( 'common' );
	const [ serverError, setServerError ] = useState( '' );

	let user = {
		first_name: '',
		last_name: ''
	};

	if ( isLoggedIn() ) {
		const { data, isLoading } = getUser( false );
		// post login offline savings

		if ( isLoading )
			return <Loader />;

		if ( ! data || ! data.results )
			return <Loader />;

		const activeNotes = getPushNotes().get().results;
		unread = activeNotes.filter( ( { is_read } ) => !is_read ).length;

		user = data.results[0];
	}

	const getAddressFor = ( routeName: string ): string => {
		const findings = SettingsRoutes.find( ( { name } ) => name === routeName );

		if ( ! findings )
			return '/404';

		return findings.link;
	};

	const deleteAccount = () => {
		HeyServer.post( '/user/gdpr_delete/' )
			.then( () => {
				localStorage.clear();
				window.location.href = '/';
			} )

			.catch( () =>
				setServerError(
					'Bei der Löschung ist etwas schiefgelaufen. Bitte versuche es später noch einmal.'
				)
			);
	};

	const deletionPrompt = (): void => {
		confirmAlert( {
			customUI: ( { onClose } ): any => (
				<div className="Prompt deletion">
					<h2>{ t( 'basics.infos' ) }</h2>

					<p>{ t( 'settings.delete_account.info_1' ) }</p>

					<p>{ t( 'settings.delete_account.info_2' ) }</p>

					<div className="Prompt__container">
						<Button
							label={ t( 'basics.abort' ) }
							onClick={ onClose }
							overrideClassName="Button__secondary Button__sm"
						/>

						<Button
							label={ t( 'basics.delete' ) }
							onClick={ deleteAccount }
							overrideClassName="Button__danger Button__sm"
						/>
					</div>
				</div>
			)
		} );
	};

	// qr codes
	const items = [
		// public routes
		{
			badge: 0,
			label: t( 'settings.privacy' ),
			disabled: false,
			address: getAddressFor( 'policy_page' ),
			Icon: <ShieldSVG />
		},
		{
			badge: 0,
			label: t( 'settings.agb' ),
			disabled: false,
			address: getAddressFor( 'terms_page' ),
			Icon: <GridSVG />
		},
		{
			badge: 0,
			label: t( 'settings.licences' ),
			disabled: false,
			address: getAddressFor( 'licenses_page' ),
			Icon: <LayersSVG />
		},
		{
			badge: 0,
			label: t( 'settings.help' ),
			disabled: false,
			address: getAddressFor( 'help_page' ),
			Icon: <HelpSVG />
		}
	];

	if ( isLoggedIn() ) {
		items.unshift(
			{
				badge: 0,
				label: t( 'settings.billing' ),
				disabled: !isOnline(),
				address: getAddressFor( 'billingInfo_page' ),
				Icon: <HomeSVG />
			},
			{
				badge: 0,
				label: t( 'settings.orders.title' ),
				disabled: !isOnline(),
				address: getAddressFor( 'orders_page' ),
				Icon: <PaperSVG />
			},
			{
				badge: 0,
				label: t( 'settings.change_pw.title' ),
				disabled: !isOnline(),
				address: getAddressFor( 'changePassword_page' ),
				Icon: <LockSVG />
			},
			{
				badge: unread,
				label: t( 'settings.notifications.title' ),
				disabled: false,
				address: getAddressFor( 'notifications_page' ),
				Icon: <BellSVG />
			}
		);
	}

	return (
		<Page title={ t( 'settings.title' ) }>
			<Card>
				<VerticalMenu
					type="settings-menu"
					onClick={ ( name ) => route.push( name ) }
					items={ items }
				/>
			</Card>

			<Card>
				{ isOnline() && (
					<VerticalMenu
						type="settings-menu"
						onClick={ ( name ) => route.push( name ) }
						items={ [
							{
								label: isLoggedIn()
									? `${t( 'settings.logout' )} (${user.first_name} ${user.last_name
									})`
									: t( 'basics.login' ),
								disabled: false,
								address: isLoggedIn() ? '/logout' : '/login',
								Icon: <LoginSVG />
							}
						] }
					/>
				) }
				{ ! isOnline() && isLoggedIn() && (
					<VerticalMenu
						type="settings-menu"
						onClick={ ( name ) => route.push( name ) }
						items={ [
							{
								label: `${t( 'settings.logout' )} (${user.first_name} ${user.last_name
									})`,
								disabled: false,
								address: '/logout',
								Icon: <LoginSVG />
							}
						] }
					/>
				) }
			</Card>

			{ isOnline() && isLoggedIn() && (
				<Button
					overrideClassName="Button__danger Button__sm"
					label={ t( 'settings.delete_account.title' ) }
					onClick={ () => deletionPrompt() }
				/>
			) }
		</Page>
	);
};
