/* eslint-disable react/destructuring-assignment */
/* eslint-disable react/jsx-props-no-spreading */
/* eslint consistent-return: "off" */

import { useEffect, useState } from 'react';
import {
	BrowserRouter as Router,
	Routes,
	Route,
	Outlet,
	useLocation,
	Navigate
} from 'react-router-dom';
import StartRoutes from '../features/Start/Start.routes';
import SettingRoutes from '../features/Settings/Settings.routes';
import AuthRoutes from '../features/Authentication/Auth.routes';
import ShopRoutes from '../features/Shop/Shop.routes';
import NotFound from '../features/Settings/404.page';
import { isLoggedIn, isOnline } from '../utils/helpers';
import { RouteObject } from '../types/Types';

export default ( props: any ): JSX.Element => {
	const all_routes = [
		...StartRoutes,
		...SettingRoutes,
		...AuthRoutes,
		...ShopRoutes
	];

	const public_routes = all_routes.filter( ( { isProtected } ) => !isProtected );
	const protected_routes = all_routes.filter( ( { isProtected } ) => isProtected );

	const RenderControl = () => {
		const location = useLocation();
		return isLoggedIn() ? (
			<Outlet />
		) : (
			<Navigate to="/login" replace state={{ from: location }} />
		);
	};

	const renderRoutes = ( list: RouteObject[] ) =>
		list.map( ( { link, component, availableOffline }: RouteObject ) => (
			<Route
				key={link}
				path={link}
				element={offlineMiddleware( component, availableOffline )}
			/>
		) );

	const offlineMiddleware = (
		component: any,
		availableOffline: boolean | undefined
	) => {
		if ( !isOnline() ) {
			if ( availableOffline ) {
				return component;
			}

			return <Navigate to="/" />;
		}

		return component;
	};

	const customProps: any = {};

	if ( props.history ) {
		customProps.history = props.history;
	}

	return (
		<Router {...customProps}>
			<Routes>
				{renderRoutes( public_routes )}
				<Route path="/" element={<RenderControl />}>
					{renderRoutes( protected_routes )}
				</Route>
				<Route path="*" element={<NotFound />} />
			</Routes>
		</Router>
	);
};
