import { useState } from 'react';
import { Formik } from 'formik';
import { useTranslation } from 'react-i18next';
import { Input } from '../../components/Input';
import { Page } from '../../components/Page';
import { Card } from '../../components/Card';
import { Button } from '../../components/Button';
import { useRouter } from '../../routes/Router.hooks';
import { ReactComponent as EyeSVG } from '../../assets/vectors/eye.svg';
import { PasswordStrengthMeter } from '../../components/modular/PasswordStrengthMeter';
import { HeyServer } from '../../utils/server';
import { Alert } from '../../components/Alert';
import { Divider } from '../../components/Divider';
import { getUser } from './Settings.data';
import { Loader } from '../../components/Loader';

export default (): any => {
	const route = useRouter();
	const [ t ] = useTranslation( 'common' );
	const { data, isLoading } = getUser( true );
	const [ serverSuccess, setServerSuccess ] = useState( '' );
	const [ serverError, setServerError ] = useState( '' );
	const [ passwordView, setPasswordView ] = useState( true );

	if ( isLoading )
		return <Loader />;

	const { email } = data.results[0];

	const onSubmit = ( values, { setSubmitting } ) => {
		HeyServer.post( '/user/change_password/', {
			old_pw: values.old_pw,
			new_pw: values.new_pw
		} )
			.then( () => {
				setServerSuccess( 'settings.change_pw.success' );
				setTimeout( () => route.push( '/' ), 3000 );
			} )
			.catch( ( e ) => setServerError( e ) )
			.finally( () => setSubmitting( false ) );
	};

	const validate = ( values ) => {
		const errors: any = {};

		if ( ! values.old_pw ) {
			errors.old_pw = t( 'errors.form.empty', {
				value: t( 'form_labels.old_pw' )
			} );
		}

		if ( ! values.new_pw ) {
			errors.new_pw = t( 'errors.form.empty', {
				value: t( 'form_labels.new_pw' )
			} );
		}

		if ( ! values.repeat_password )
			errors.repeat_password = t( 'errors.form.repeat' );

		if ( ! values.isPasswordStrong )
			errors.new_pw = t( 'errors.form.password' );

		if ( values.new_pw !== values.repeat_password )
			errors.repeat_password = t( 'errors.form.no_match' );

		return errors;
	};

	return (
		<Page title={ t( 'settings.change_pw.title' ) }>
			{ serverError && (
				<Alert type="error" show>
					<p>{ serverError }</p>
				</Alert>
			) }
			{ serverSuccess && (
				<Alert type="success" show>
					<p>{ serverSuccess }</p>
				</Alert>
			) }
			<Card>
				<Formik
					initialValues={ {
						old_pw: '',
						new_pw: '',
						repeat_password: ''
					} }
					validate={ validate }
					validateOnChange={ false }
					validateOnBlur={ false }
					onSubmit={ onSubmit }
				>
					{ ( {
						values,
						errors,
						handleChange,
						handleSubmit,
						isSubmitting,
						setFieldValue
					} ) => (
						<form onSubmit={ handleSubmit }>
							<span>{ t( 'settings.change_pw.info' ) }</span>
							<br />
							<div className="__Input-password">
								<Input
									inputType={ passwordView ? 'password' : 'text' }
									name="old_pw"
									type="with-label"
									label={ t( 'settings.change_pw.old_pw' ) }
									onChange={ ( _, e ) => handleChange( e ) }
									value={ values.old_pw }
									onErrorMessage={ errors.old_pw as string }
									child={ <EyeSVG onClick={ () => setPasswordView( ! passwordView ) } /> }
								/>
							</div>

							<Divider />
							<br />

							<div className="__Input-password">
								<Input
									inputType={ passwordView ? 'password' : 'text' }
									name="new_pw"
									type="with-label"
									label={ t( 'settings.change_pw.new_pw' ) }
									onChange={ ( _, e ) => handleChange( e ) }
									value={ values.new_pw }
									onErrorMessage={ errors.new_pw as string }
									child={ <EyeSVG onClick={ () => setPasswordView( ! passwordView ) } /> }
								/>
							</div>

							<PasswordStrengthMeter
								email={ email }
								password={ values.new_pw }
								isAcceptable={ ( s ) => setFieldValue( 'isPasswordStrong', s ) }
							/>

							<div className="__Input-password">
								<Input
									inputType={ passwordView ? 'password' : 'text' }
									name="repeat_password"
									type="with-label"
									label={ t( 'settings.change_pw.repeat' ) }
									onChange={ ( _, e ) => handleChange( e ) }
									value={ values.repeat_password }
									onErrorMessage={ errors.repeat_password as string }
									child={ <EyeSVG onClick={ () => setPasswordView( ! passwordView ) } /> }
								/>
							</div>

							<Button
								actionType="submit"
								onClick={ handleSubmit }
								label={ t( 'settings.change_pw.button' ) }
								disabled={ isSubmitting }
							/>
						</form>
					) }
				</Formik>
			</Card>
		</Page>
	);
};
