import { useEffect } from 'react';
import { Loader } from '../../components/Loader';
import { ShopPresistor } from '../Shop/Shop.data';

export default (): any => {
	useEffect( () => {
		setTimeout( () => {
			ShopPresistor().reset();
			localStorage.clear();
			window.location.href = '/';
		}, 200 );
	} );

	return <Loader />;
};
