// eslint-disable jsx-props-no-spreading
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import moment, { Moment } from 'moment';
import DatePicker from 'react-datetime';
import 'react-datetime/css/react-datetime.css';
import { Page } from '../../components/Page';
import { useRouter } from '../../routes/Router.hooks';
import { getTicketTypes, ShopPresistor } from './Shop.data';
import { Loader } from '../../components/Loader';
import { Card } from '../../components/Card';
import { ReactComponent as CalendarSVG } from '../../assets/vectors/calendar.svg';
import { Stepper, StepperItemsType } from '../../components/modular/Stepper';
import { Button } from '../../components/Button';
import { getSettings } from '../Settings/Settings.data';
import { Divider } from '../../components/Divider';

export type BookedDatesType = { date: string; remaining_quota: number };
// make sure the given date does not lie outside of season start/end and if quota is enbaled, there is more than 0 places
export const isValidDate = (
	givenDate: Moment,
	booked_dates: BookedDatesType[],
	quota_enabled: boolean,
	seasonEndDate: string
): boolean => {
	const findDisabledDates = () => {
		const disabled_dates: any = [];
		const exclusions = [...booked_dates];

		// get disabled dates in 1D-array
		quota_enabled &&
			exclusions.forEach(
				( { date, remaining_quota } ) =>
					remaining_quota === 0 && date && disabled_dates.push( date )
			);

		return disabled_dates;
	};

	const startDate = moment();

	// check if it is in the season range
	if (
		givenDate.isBefore( moment( seasonEndDate ).add( 12, 'h' ) ) &&
		givenDate.isAfter( startDate )
	) {
		// then check if it is not in the disabled
		return ! findDisabledDates().includes(
			moment( givenDate ).format( 'YYYY-MM-DD' )
		);
	}

	return false;
};

// how many quotas are available for the given date
export const getQuota = (
	inputDate,
	quota_enabled = false,
	booked_dates,
	withRender = false
) => {
	if ( !quota_enabled )
		return true;

	let count = 0;
	booked_dates.forEach( ( el ) => {
		if ( moment( el.date ).isSame( inputDate.format( 'YYYY-MM-DD' ) ) ) {
			count = el.remaining_quota;
		}
	} );

	if ( withRender ) {
		return (
			<div className="QuotaCount">
				<div style={ { fontSize: 13, color: '#7D8385' } }>
					noch { count } übrig!
				</div>
			</div>
		);
	}

	return count;
};

export const nextPage = ( MomentDate: Moment, route ) => {
	ShopPresistor().setSelections( {
		date: MomentDate.format( 'YYYY-MM-DD' )
	} );

	let p = `/shop/select-pax`;
	if ( route.query.pax_override )
		p += `?pax_override=${route.query.pax_override}`;

	if ( route )
		route.push( p );
};

export default (): JSX.Element => {
	const route = useRouter();
	const [ t ] = useTranslation( 'common' );
	const [ selectedDate, setSelectedDate ] = useState<string | Moment>( moment().format( 'YYYY-MM-DD' ) );
	const [ calendarToggle, setCalendarToggle ] = useState( false );

	// redirect back to first step of params are corrupt
	const { ttid, date: localDate } = ShopPresistor().getSelections();
	const [ localLoaded, setLocalLoaded ] = useState( false );
	if ( ! ttid )
		route.push( '/shop' );

	if ( ! localLoaded && localDate ) {
		setLocalLoaded( true );
		setSelectedDate( localDate );
	}

	const { data, isLoading } = getTicketTypes( false );
	const { data: settingsData, isLoading: settingsLoading } = getSettings( false );

	if ( isLoading || settingsLoading )
		return <Loader />;

	const settings = settingsData.results[0];
	const seasonEndDate = settings.active_season.end_date;
	const ticketType = data.results.find( ( { id } ) => id === Number( ttid ) );

	if ( ! ticketType )
		route.push( '/shop' );

	const { quota_enabled, booked_dates } = ticketType;

	const stepperItems: StepperItemsType[] = [
		{
			title: t( 'progress.date' ),
			filled: false,
			active: true
		},
		{
			title: t( 'progress.amount' ),
			filled: false,
			active: false
		},
		{
			title: t( 'progress.contacts' ),
			filled: false,
			active: false
		}
	];

	const today = moment( moment() );
	const tmrw = moment( moment().add( 1, 'days' ) );
	// if the url param has incert override, go for loading the package

	const todaysQuota = getQuota( today, quota_enabled, booked_dates );
	const tmrwsQuota = getQuota( tmrw, quota_enabled, booked_dates );

	const latestHour = ticketType.latest_hour ?? settings.closing_time;
	const tooLate = moment().hour() >= latestHour

	return (
		<Page title={ t( 'shop.date.title' ) }>
			<Stepper items={ stepperItems } />
			<div className="TicketDateContainer">
				<div
					className={
						! todaysQuota || tooLate
							? 'CardDisabled'
							: ''
					}
				>
					<Card onClick={ () => todaysQuota && ! tooLate && nextPage( today, route ) }>
						<p>{ t( 'shop.date.dates.today' ) }</p>

						<span data-cy="span__todays-date">
							{ today.format( 'ddd DD.MM.YYYY' ) }
						</span>

						{ ! todaysQuota && (
							<b>{ t( 'shop.date.booked_out' ) }</b>
						) }
					</Card>
				</div>

				<div
					className={
						! tmrwsQuota
							? 'CardDisabled'
							: ''
					}
				>
					<Card onClick={ () => tmrwsQuota && nextPage( tmrw, route ) }>
						<p>{ t( 'shop.date.dates.tomorrow' ) }</p>

						<span data-cy="span__tmrws-date">
							{ tmrw.format( 'ddd DD.MM.YYYY' ) }
						</span>

						{ ! tmrwsQuota && (
							<b>{ t( 'shop.date.booked_out' ) }</b>
						) }
					</Card>
				</div>

				<Divider label={ t( 'shop.date.seperator' ) } />

				<div className="CustomDateBox">
					<DatePicker
						isValidDate={ ( givenDate ) =>
							isValidDate( givenDate, booked_dates, quota_enabled, seasonEndDate )
						}
						dateFormat="YYYY-MM-DD"
						closeOnSelect
						renderInput={ ( { value }, openCalendar, closeCalendar ) => (
							<h2
								aria-hidden
								onClick={ () => calendarToggle ? closeCalendar() : openCalendar() }
							>
								<CalendarSVG /> { value }
							</h2>
						) }
						value={ selectedDate }
						timeFormat={ false }
						onChange={ ( date ) => {
							setSelectedDate( date );
						} }
						onOpen={ () => setCalendarToggle( true ) }
						onClose={ () => setCalendarToggle( false ) }
					/>
				</div>
			</div>

			<br />

			<Button
				type="primary"
				label={ t( 'buttons.continue' ) }
				cy="button__continue"
				onClick={ () => nextPage( moment( selectedDate ), route ) }
			/>
		</Page>
	);
};
